@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	background: $white;
	.header-bnr {
		position: relative;
		&__btn {
			display: block;
			position: absolute;
			bottom: 7px;
			left: 12px;
			width: 158px;
		}
	}
	.gnav {
		display: flex;
		margin: 0 10px;
		padding-bottom: 30px;
		
		&__item {
			color: $white;
			font-family: "Kosugi Maru", sans-serif;
			font-weight: 400;
			font-style: normal;
			margin: 0 10px;
			width: calc((100% - 61px) / 3);
			display: flex;
			justify-content: center;
			align-items: center;
			height: 55px;
			border-radius: 4px;
			text-decoration: none;
			position: relative;
			
			.gnav__item__text {
				position: relative;
				&::before {
					content: "";
					@include centering-elements(false, true);
					left: 0;
				}
			}
			
			&--mayotako {
				background: #ffe900;
				color: #4d4d4d;
				.gnav__item__text {
					padding-left: 50px;
					&::before {
						background: url(/common/img/common/icon_mayo_off.svg) no-repeat;
						background-size: 45px 35px;
						width: 45px;
						height: 35px;
						@include transition;
					}
				}
				&:hover {
					.gnav__item__text {
						&::before {
							background: url(/common/img/common/icon_mayo_on.svg) no-repeat;
							background-size: 45px 35px;
						}
					}
				}
			}
			&--kotobuki {
				background: #ff917f;
			}
			&--tendominami {
				background: #63c9e4;
			}
			&--kotobuki,
			&--tendominami {
				padding: 0 15px;
				.gnav__item__text {
					width: 100%;
					line-height: 1.2;
					font-size: $xl;
					padding-left: 30px;
					&::before {
						background: url(/common/img/common/icon_menu_off.svg) no-repeat;
						background-size: 23px 24px;
						width: 23px;
						height: 24px;
						@include transition;
					}
				}
				&:hover {
					.gnav__item__text {
						&::before {
							background: url(/common/img/common/icon_menu_on.svg) no-repeat;
							background-size: 23px 24px;
						}
					}
				}
			}
			.icon-angle-right {
				@include centering-elements(false, true);
				right: 10px;
			}
		}
	}
}


