@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.index-bookmark-btn {
		background: #d8eeff;
		font-size: $xxs;
		padding: 1px 6px;
		border: 2px outset;
		border-image: initial;
		font-weight: 400;
		font-family: "Arial";
	}
	.index-sale {
		position: relative;
		margin-bottom: 10px;
		&__text {
			position: absolute;
			bottom: 0;
			left: 0;
			font-size: $xxxs;
		}
	}
	.index-info {
		width: 100%!important;
		overflow: auto!important;
		height: 200px;
		-webkit-overflow-scrolling: touch !important;
	}
	.index-menu {
		max-width: none;
		&__item {
			font-size: $xxs;
			border-right : 1px solid $baseColor;
			padding-bottom: 65px;
			&--negi {
				background: url(/common/img/common/pic_menu_negi.jpg) right bottom no-repeat;
				background-size: 100% auto;
			}
			&--piri {
				background: url(/common/img/common/pic_menu_piri.jpg) right bottom no-repeat;
				background-size: 100% auto;
			}
			&--shio {
				background: url(/common/img/common/pic_menu_sio.jpg) right bottom no-repeat;
				background-size: 100% auto;
			}
			&--half {
				background: url(/common/img/common/pic_menu_ha.jpg) right bottom no-repeat;
				background-size: 100% auto;
			}
		}
	}
}