@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	border: 1px solid $borderColor;
	border-bottom: none;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	line-height: 1.7;
	background: #d0d2c9;
	font-size: $xs;
	margin: 0 20px;

	.footer-inner {
		border: 4px solid $white;
		border-bottom: none;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		padding: 20px 20px 0;
	}

	.footer-logo {
		border-top: 1px solid $white;
		padding: 10px 0;
		text-align: center;
	}
}