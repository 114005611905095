@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	background: $white;
	.header-bnr {
		position: relative;
		&__btn {
			display: block;
			position: absolute;
			bottom: 7px;
			left: 12px;
			width: 158px;
		}
	}
	.gnav {
		display: flex;
		margin: 0 5px 10px;
		flex-wrap: wrap;
		
		&__item {
			color: $white;
			font-family: "Kosugi Maru", sans-serif;
			font-weight: 400;
			font-style: normal;
			margin: 0 5px 10px;
			width: calc((100% - 21px) / 2 );
			display: flex;
			justify-content: center;
			align-items: center;
			height: 55px;
			border-radius: 4px;
			text-decoration: none;
			font-size: $xl;
			position: relative;
			padding-right: 10px;
			
			.gnav__item__text {
				position: relative;
				&::before {
					content: "";
					@include centering-elements(false, true);
					left: 0;
				}
			}
			
			&--home {
				background: #20a527;
				.gnav__item__text {
					padding-left: 23px;
					&::before {
						background: url(/common/img/common/icon_home.svg) no-repeat;
						background-size: 18px 20px;
						width: 18px;
						height: 20px;
					}
				}
			}
			
			&--mayotako {
				background: #ffe900;
				color: #4d4d4d;
				.gnav__item__text {
					padding-left: 50px;
					&::before {
						background: url(/common/img/common/icon_mayo_off.svg) no-repeat;
						background-size: 45px 35px;
						width: 45px;
						height: 35px;
					}
				}
			}
			&--kotobuki {
				background: #ff917f;
			}
			&--tendominami {
				background: #63c9e4;
			}
			&--kotobuki,
			&--tendominami {
				.gnav__item__text {
					font-size: $m;
					line-height: 1.3;
					padding-left: 25px;
					&::before {
						background: url(/common/img/common/icon_menu_off.svg) no-repeat;
						background-size: 23px 24px;
						width: 23px;
						height: 24px;
					}
				}
			}
				
			.icon-angle-right {
				@include centering-elements(false, true);
				right: 10px;
			}
		}
	}
}

