@charset "utf-8";
/* ============================================================ */
/* menu.html */
/* ============================================================ */
.menu {
	.menu-title {
		background: $baseColor;
		color: $white;
		font-size: $xxs;
		padding: 0 10px;
		margin-bottom: 10px;
	}
	.menu-list {
		max-width: none;
		&__item {
			font-size: $xxs;
			border-right : 1px solid $baseColor;
			padding-bottom: 50px;
			&--mayo {
				width: 400px;
				padding-bottom: 0;
				border: 1px solid $baseColor;
			}
			&--negi {
				background: url(/common/img/common/pic_menu_negi.jpg) right bottom no-repeat;
			}
			&--piri {
				background: url(/common/img/common/pic_menu_piri.jpg) right bottom no-repeat;
			}
			&--shio {
				background: url(/common/img/common/pic_menu_sio.jpg) right bottom no-repeat;
			}
			&--half {
				background: url(/common/img/common/pic_menu_ha.jpg) right bottom no-repeat;
			}
		}
	}
}
	